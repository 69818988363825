import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import calculateOrderBox from "../helpers/orderBoxCalcHelper";
import { calculatePackages } from "../helpers/packageCalculation";
import { calculateVolume } from "../helpers/volumeHelper";

// Define styles for PDF document
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 10,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    alignItems: "center",
    padding: 5,
  },
  headerCell: {
    width: "17%",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
  },
  headerCellSmall: {
    width: "10.6%",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
  },
  cell: {
    width: "17%",
    textAlign: "center",
    fontSize: "12px",
  },
  cellSmall: {
    width: "10.6%",
    textAlign: "center",
    fontSize: "12px",
  },
  name: {
    width: "20%",
    textAlign: "center",
    fontSize: "12px",
  },
});

const PDFDocument = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} title="agroverde.ro">
        {/* Header Row */}
        <View style={styles.row}>
          <Text style={styles.headerCell}>Name product</Text>
          <Text style={styles.headerCell}>Current Stock(pcs)</Text>
          <Text style={styles.headerCell}>Min. stock(pcs)</Text>
          <Text style={styles.headerCell}>Order(pcs)</Text>
          <Text style={styles.headerCellSmall}>Total Box/Roll packages</Text>
          <Text style={styles.headerCellSmall}>Total Box/Roll (m3):</Text>
          <Text style={styles.headerCellSmall}>Total weight (kg):</Text>
        </View>

        {/* Data Rows */}
        {data
          .filter(
            (item) => item.cantitate < item.minStock && item.minStock !== "",
          )
          .map((item, index) => {
            return (
              <View key={index} style={styles.row}>
                <Text style={styles.name}>
                  {item.den_produs_en !== ""
                    ? item.den_produs_en
                    : item.den_produs}
                </Text>
                <Text style={styles.cell}>{item.cantitate}</Text>
                <Text style={styles.cell}>{item.minStock}</Text>
                <Text style={styles.cell}>
                  {item.cantitate < item.minStock && item.minStock !== 0
                    ? isNaN(calculateOrderBox(item)) ||
                      calculateOrderBox(item) -
                        item.total_in_production -
                        item.total_in_delivery <=
                        0
                      ? 0
                      : calculateOrderBox(item) -
                        item.total_in_production -
                        item.total_in_delivery
                    : 0}
                </Text>
                <Text style={styles.cellSmall}>
                  {item.minStock === 0 ||
                  calculateOrderBox(item) -
                    item.total_in_production -
                    item.total_in_delivery <=
                    0
                    ? 0
                    : isNaN(calculatePackages(item))
                      ? 0
                      : calculatePackages(item)}
                </Text>
                <Text style={styles.cellSmall}>
                  {isNaN(calculateVolume(item, calculatePackages(item)))
                    ? 0
                    : calculateVolume(item, calculatePackages(item))}
                </Text>
                <Text style={styles.cellSmall}>
                  {isNaN(calculatePackages(item) * item.weight_bax_pallet) ||
                  calculateOrderBox(item) -
                    item.total_in_production -
                    item.total_in_delivery <=
                    0
                    ? 0
                    : (
                        calculatePackages(item) * item.weight_bax_pallet
                      ).toFixed(3)}
                </Text>
              </View>
            );
          })}
      </Page>
    </Document>
  );
};

export default PDFDocument;
