import React from "react";
import styled from "styled-components";
import PDFDocument from "./PDFdocument";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";

const Wrapper = styled.div`
  border: 1px solid #000;
  padding: 0 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    max-width: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    cursor: pointer;
  }
`;

const ExportFile = ({ filteredData }) => {
  return (
    <Wrapper>
      <PDFDownloadLink
        document={<PDFDocument data={filteredData} />}
        fileName="agroverde_ro.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download PDF"
        }
      </PDFDownloadLink>
    </Wrapper>
  );
};

export default ExportFile;
